import Axios from 'axios';
import NProgress from 'nprogress';
import { BASE_API, US_BASE_API } from '../constant';
import { isClient } from 'utils/common';
import filter from 'lodash/filter';
import mapValues from 'lodash/mapValues';
import notification from 'utils/notification';
import capitalize from 'lodash/capitalize';
import first from 'lodash/first';

const JSON_HEADER = 'application/json';

const REQ_TIMEOUT = 3 * 60 * 1000; // 10 minutes

const axiosInstance = Axios.create({
  baseURL: BASE_API,
  timeout: REQ_TIMEOUT,
});

axiosInstance.defaults.headers.common['Accept'] = JSON_HEADER;
axiosInstance.defaults.headers.common['Content-Type'] = JSON_HEADER;

let apiCallsCounter: any = {};
let isStoppingTimeout: any = null;
let globalStoppingTimeout: any = null;

const startLoading = (url: string) => {
  if (isStoppingTimeout) {
    clearTimeout(isStoppingTimeout);
  }
  NProgress.start();
  apiCallsCounter[url] = true;

  if (!globalStoppingTimeout) {
    globalStoppingTimeout = setTimeout(() => {
      NProgress.done();
      globalStoppingTimeout = null;
      apiCallsCounter = {};
      isStoppingTimeout = null;
    }, REQ_TIMEOUT);
  }
};

const stopLoading = (url: string) => {
  apiCallsCounter[url] = false;

  // request cancelled with no info
  if (!url) {
    apiCallsCounter = mapValues(apiCallsCounter, () => false);
  }

  if (!filter(apiCallsCounter, Boolean).length) {
    isStoppingTimeout && clearTimeout(isStoppingTimeout);
    isStoppingTimeout = setTimeout(() => {
      NProgress.done();

      if (globalStoppingTimeout) {
        clearTimeout(globalStoppingTimeout);
      }
      apiCallsCounter = {};
      isStoppingTimeout = null;
      globalStoppingTimeout = null;
    }, 250);
  }
};

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers.silentFetch) {
      if (isClient() && config.url) {
        startLoading(config.url);
      }
    }
    config.headers['req-scope'] = `MH-CP`;
    config.headers['origin'] = `restaurant.momos.io`;
    if (typeof window !== 'undefined') {
      config.headers['origin-url'] = window?.location?.href;
    }
    if (isClient()) {
      const host = window?.location?.hostname;
      if (host.startsWith('us-')) {
        config.baseURL = US_BASE_API;
      }
    }

    return config;
  },
  (error) => {
    if (isClient()) {
      stopLoading(error?.config?.url);
    }

    throw error;
  },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    if (isClient() && response.config.url) {
      stopLoading(response.config.url);
    }

    return response;
  },
  (error) => {
    if (isClient()) {
      stopLoading(error?.config?.url);
    }
    const errMessage = error?.response?.data?.message || error?.response?.data?.errorMessage;

    const msg: string | undefined =
      typeof errMessage === 'string' ? errMessage : first(Object.values(errMessage || {}));

    if (msg && isClient()) {
      notification(capitalize(msg), 'Error', 'error');
    }

    throw error;
  },
);

export default axiosInstance;
