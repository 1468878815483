import { ReactNode } from 'react';
import { DefaultButtonType } from 'utils/types';
import { PARTNER } from './partner';
import {
  FacebookIcon as FBIcon,
  GoogleIcon as GIcon,
  InstagramIcon as InstaIcon,
  MessengerIcon,
  LinkedinIcon,
  TiktokIcon,
} from 'assets/svg/microsite';
import {
  YelpIcon,
  DoorDashIcon,
  WhatsappIcon,
  TwitterIcon,
  YoutubeIcon,
  SnapchatIcon,
  PinterestIcon,
  LineIcon,
  SpotifyIcon,
  GooglePlayIcon,
  ApplePlayIcon,
  TripAdvisorLogo,
} from 'assets/svg';
import { REGION, REGIONS } from 'constant';

export const ThirdPartyLinksTypeQuery: Record<string, string> = {
  SOCIAL_MEDIA: 'social-media',
  RESERVATION: 'reservation',
  FOOD_DELIVERY: 'food-delivery',
};

export const ThirdPartyLinksType: Record<string, string> = {
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
  RESERVATION: 'RESERVATION',
  FOOD_DELIVERY: 'FOOD_DELIVERY',
};

export const OperatingHoursType: Record<string, string> = {
  DINE_IN: 'dine_in',
  DELIVERY: 'delivery',
};

export const SOCIAL_MEDIA_PLATFORM: Record<string, string> = {
  GMB: PARTNER.GMB,
  FACEBOOK: PARTNER.FACEBOOK,
  INSTAGRAM: 'INSTAGRAM',
  MESSENGER: 'MESSENGER',
  YELP: 'YELP',
  WHATSAPP: 'WHATSAPP',
  TWITTER: 'TWITTER',
  SNAPCHAT: 'SNAPCHAT',
  PINTEREST: 'PINTEREST',
  YOUTUBE: 'YOUTUBE',
  LINE: 'LINE',
  SPOTIFY: 'SPOTIFY',
  TRIP_ADVISOR: PARTNER.TRIP_ADVISOR,
};

export const SOCIAL_MEDIA_NAME = {
  [SOCIAL_MEDIA_PLATFORM.TRIP_ADVISOR]: 'Trip Advisor',
};

export const SERVICE_OPTIONS_LABELS: Record<string, string> = {
  TAKE_AWAY: REGION === REGIONS.US ? 'Takeout' : 'Takeaway',
  DELIVERY: 'Delivery',
  DINE_IN: 'Dine-in',
};

export const MICROSITE_BUTTON_TYPE: Record<string, string> = {
  DEFAULT: 'DEFAULT',
  CUSTOM: 'CUSTOM',
};

export const MICROSITE_BUTTON_SLUG: Record<string, string> = {
  Menu: '/menus',
  Delivery: '/order-for-delivery',
  Reservation: '/make-reservation',
  Survey: '/survey',
};

export const SURVEY_ORDER_TYPES: Record<string, string> = {
  DELIVERY: 'DELIVERY',
  DINE_IN: 'DINE_IN',
  TAKE_AWAY: 'TAKE_AWAY',
};

export const FontsList: Record<string, string>[] = [
  { value: 'Arial', label: 'Arial' },
  { value: 'Gilroy-Regular', label: 'Gilroy' },
  { value: 'Montserrat-Regular', label: 'Montserrat' },
  { value: 'Druk-Bold-Regular', label: 'Druk Bold Regular' },
  { value: 'Baby-School-Italic', label: 'Baby School Italic' },
  { value: 'Barlow-Bold', label: 'Barlow Bold' },
];

export const HOME_PAGE_DEFAULT_BUTTON: Record<string, string> = {
  MENU: 'Menu',
  DELIVERY: 'Delivery',
  RESERVATION: 'Reservation',
  SURVEY: 'Survey',
  WEBSITE: 'Website',
};

export const MicrositeDefaultButtons: DefaultButtonType[] = [
  {
    id: '1',
    name: HOME_PAGE_DEFAULT_BUTTON.MENU,
    value: 'Our Menu',
    type: MICROSITE_BUTTON_TYPE.DEFAULT,
    status: true,
  },
  {
    id: '2',
    name: HOME_PAGE_DEFAULT_BUTTON.DELIVERY,
    value: 'Order',
    type: MICROSITE_BUTTON_TYPE.DEFAULT,
    status: true,
  },
  {
    id: '3',
    name: HOME_PAGE_DEFAULT_BUTTON.RESERVATION,
    value: 'Make a Reservation',
    type: MICROSITE_BUTTON_TYPE.DEFAULT,
    status: true,
  },
  {
    id: '4',
    name: HOME_PAGE_DEFAULT_BUTTON.SURVEY,
    value: 'Give us Feedback',
    type: MICROSITE_BUTTON_TYPE.DEFAULT,
    status: true,
  },
];

export const SCHEDULE_DAYS_NUMBER: Record<number, string> = {
  0: 'SUNDAY',
  1: 'MONDAY',
  2: 'TUESDAY',
  3: 'WEDNESDAY',
  4: 'THURSDAY',
  5: 'FRIDAY',
  6: 'SATURDAY',
  7: 'SUNDAY',
};

export const SCHEDULER = [
  { day: SCHEDULE_DAYS_NUMBER[1], isOpen: false, startTime: null, endTime: null, dataList: [] },
  { day: SCHEDULE_DAYS_NUMBER[2], isOpen: false, startTime: null, endTime: null, dataList: [] },
  { day: SCHEDULE_DAYS_NUMBER[3], isOpen: false, startTime: null, endTime: null, dataList: [] },
  { day: SCHEDULE_DAYS_NUMBER[4], isOpen: false, startTime: null, endTime: null, dataList: [] },
  { day: SCHEDULE_DAYS_NUMBER[5], isOpen: false, startTime: null, endTime: null, dataList: [] },
  { day: SCHEDULE_DAYS_NUMBER[6], isOpen: false, startTime: null, endTime: null, dataList: [] },
  { day: SCHEDULE_DAYS_NUMBER[0], isOpen: false, startTime: null, endTime: null, dataList: [] },
];

export const SURVEY_STEPS: Record<string, string> = {
  METHOD_OF_ORDER: 'METHOD_OF_ORDER',
  RATE_EXPERIENCE: 'RATE_EXPERIENCE',
  SELECT_PLATFORM: 'SELECT_PLATFORM',
  SELECT_LOCATION: 'SELECT_LOCATION',
  CONTACT_DETAILS: 'CONTACT_DETAILS',
  SHARE_FEEDBACK: 'SHARE_FEEDBACK',
  THANK_YOU: 'THANK_YOU',
  NO_VOUCHER: 'NO_VOUCHER',
  SELECT_STAFF: 'SELECT_STAFF',
  STAFF_RATE_EXPERIENCE: 'STAFF_RATE_EXPERIENCE',
};

export const SENTIMENT_TYPE: Record<string, string> = {
  POSITIVE: 'POSITIVE',
  NEGATIVE: 'NEGATIVE',
};

export const TAG_TYPE: Record<string, string> = {
  LIKE: 'LIKE',
  DISLIKE: 'DISLIKE',
};

export const SURVEY_QUESTIONS_TYPE: Record<string, string> = {
  STAFF: 'STAFF',
  MASTER: 'MASTER',
};

export const REWARD_TYPES: Record<string, string> = {
  DISCOUNT_AMOUNT: 'DISCOUNT_AMOUNT',
  PERCENTAGE_OFF: 'PERCENTAGE_OFF',
  FREE_ITEM: 'FREE_ITEM',
  NO_REWARD: 'NO_REWARD',
};

export const SOCIAL_MEDIA_ICONS: Record<string, ReactNode> = {
  Facebook: <FBIcon />,
  Google: <GIcon />,
  Instagram: <InstaIcon />,
  Messenger: <MessengerIcon />,
  LinkedIn: <LinkedinIcon />,
  TikTok: <TiktokIcon />,
  Yelp: <YelpIcon />,
  DoorDash: <DoorDashIcon />,
  Whatsapp: <WhatsappIcon />,
  Twitter: <TwitterIcon />,
  Youtube: <YoutubeIcon />,
  Snapchat: <SnapchatIcon />,
  Pinterest: <PinterestIcon />,
  Line: <LineIcon />,
  Spotify: <SpotifyIcon />,
  'Google Play Store': <GooglePlayIcon />,
  'App Store': <ApplePlayIcon />,
  [SOCIAL_MEDIA_NAME[SOCIAL_MEDIA_PLATFORM.TRIP_ADVISOR]]: <TripAdvisorLogo />,
};

export const SOCIAL_MEDIA_KEY_MAPPED: Record<string, string> = {
  Facebook: 'FACEBOOK',
  Google: 'GOOGLE',
  Instagram: 'INSTAGRAM',
  Messenger: 'MESSENGER',
  LinkedIn: 'LINKEDIN',
  TikTok: 'TIKTOK',
  Yelp: 'YELP',
  DoorDash: 'DOORDASH',
  Youtube: 'YOUTUBE',
  Whatsapp: 'WHATSAPP',
  Snapchat: 'SNAPCHAT',
  Twitter: 'TWITTER',
  Pinterest: 'PINTEREST',
  Line: 'LINE',
  Spotify: 'SPOTIFY',
  'Google Play Store': 'GOOGLE_PLAY_STORE',
  'App Store': 'APP_STORE',
  [SOCIAL_MEDIA_NAME[SOCIAL_MEDIA_PLATFORM.TRIP_ADVISOR]]: PARTNER.TRIP_ADVISOR,
};

export const EXPRESS_SURVEY_PARAMS: Record<string, string> = {
  delivery: SURVEY_ORDER_TYPES.DELIVERY,
  dinein: SURVEY_ORDER_TYPES.DINE_IN,
  dine_in: SURVEY_ORDER_TYPES.DINE_IN,
  takeaway: SURVEY_ORDER_TYPES.TAKE_AWAY,
  take_away: SURVEY_ORDER_TYPES.TAKE_AWAY,
  pickup: SURVEY_ORDER_TYPES.TAKE_AWAY,
  pickUp: SURVEY_ORDER_TYPES.TAKE_AWAY,
  pick_up: SURVEY_ORDER_TYPES.TAKE_AWAY,
};

export const SURVEY_TYPE: Record<string, string> = {
  EXPRESS: 'EXPRESS',
  NORMAL: 'NORMAL',
};

export const CURRENCIES: Record<string, string> = {
  US: '$',
  SG: 'SGD',
  AU: 'AUD',
  HK: 'HKD',
  MY: 'MYR',
  TH: 'THB',
  PH: 'PHP',
};

const SESSION_SUBSCRIBED_KEY = 'session-subscribe';

export const getMicrositeSubscibrKey = (brandSlug: string) => {
  return `${SESSION_SUBSCRIBED_KEY}-${brandSlug}`;
};

export const SURVEY_TEMPLATES = {
  STD_FEEDBACK_SURVEY: 'STD_FEEDBACK_SURVEY',
  BOOST_SURVEY: 'BOOST_SURVEY',
  FEW_CLICK_FEEDBACK_SURVEY: 'FEW_CLICK_FEEDBACK_SURVEY',
  STD_FEEDBACK_SURVEY_V2: 'STD_FEEDBACK_SURVEY_V2',
};

export const GOOGLE_MAP_ADDRESS_BUILD_ORDER = ['address1', 'route', 'city', 'state', 'pinCode', 'countryCode'];
