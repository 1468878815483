import googleFonts from 'assets/font/googleFonts.json';
import { find, forEach, isEmpty, map, uniq } from 'lodash';
import { useEffect, useState } from 'react';

export default function useGoogleFonts(fontFamilies: (string | undefined)[]) {
  const [fontLoaded, setFontLoaded] = useState(false);

  useEffect(() => {
    if (isEmpty(fontFamilies)) return;

    const linkEls = map(uniq(fontFamilies), (fontFamily) => {
      if (!fontFamily) return null;
      const font = find(googleFonts, (font) => font.value === fontFamily);
      if (!font) return null;

      const link = document.createElement('link');
      link.href = font.url;
      link.rel = 'stylesheet';

      link.onload = () => {
        setFontLoaded(true);
      };

      link.onerror = (error) => {
        console.error('Font loading error:', error);
      };

      document.head.appendChild(link);

      return link;
    });

    return () => {
      forEach(linkEls, (link) => {
        if (link) {
          document.head.removeChild(link);
        }
      });
    };
  }, [fontFamilies]);

  return fontLoaded;
}
