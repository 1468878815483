import Facebook from './facebook.svg';
import Instagram from './instagram.svg';
import Google from './google.svg';
import Messenger from './messenger.svg';
import Tiktok from './tiktok.svg';
import Linkedin from './linkedin.svg';

export const FacebookIcon = Facebook;
export const GoogleIcon = Google;
export const InstagramIcon = Instagram;
export const MessengerIcon = Messenger;
export const TiktokIcon = Tiktok;
export const LinkedinIcon = Linkedin;
