import endpoints from 'config/endpoints';
import request from 'config/request';
import { removeParamFromUrl } from 'utils/library';
import { getSessionId } from './ga';
import { isMockingEnvEnabled } from 'constant';

export default function trackingShortUrl() {
  if (isMockingEnvEnabled) {
    return;
  }
  if (typeof window !== 'undefined') {
    try {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('utm_tracking_id')) {
        const trackingId = urlParams.get('utm_tracking_id');

        const payload = {
          trackingId,
          metadata: collectMetadata(),
        };
        request.post(endpoints.momos.shorturl.tracking, payload).then(() => {
          removeParamFromUrl('utm_tracking_id');
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
}

function collectMetadata() {
  if (typeof window !== 'undefined') {
    const data = {
      userAgent: window?.navigator?.userAgent,
      screenWidth: window?.screen?.width,
      screenHeight: window?.screen?.height,
      viewportWidth: window?.innerWidth,
      viewportHeight: window?.innerHeight,
      referrer: document?.referrer,
      url: window?.location?.href,
      sessionId: getSessionId(),
    };
    return data;
  }
}
