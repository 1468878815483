import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const Loader = () => {
  return (
    <LoaderWrap>
      <Spin size="large" />
    </LoaderWrap>
  );
};

const LoaderWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Loader;
