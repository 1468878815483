import { isClient } from 'utils/common';

export const pageview = () => {
  if (isClient()) {
    window.fbq && window.fbq('track', 'PageView');
  }
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options: Record<string, string> = {}) => {
  if (isClient()) {
    window.fbq && window.fbq('trackCustom', name, options);
  }
};

export const buttonClickEvent = (options: Record<string, string> = {}) => {
  if (isClient()) {
    window.fbq && window.fbq('trackCustom', 'Button Click', options);
  }
};
