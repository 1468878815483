import BackArrow from './back-arrow.svg';
import Clock from './clock.svg';
import Call from './call.svg';
import Phone from './phone.svg';
import Marker from './marker.svg';
import Close from './close.svg';
import Truck from './truck.svg';
import TruckSmall from './truck-small.svg';
import Note from './note.svg';
import MapPin from './map-pin.svg';
import Clock2 from './clock-2.svg';
import Clock2White from './clock-white.svg';
import MapPinWhite from './map-pin-white.svg';
import Frame from './frame.svg';
import Global from './global.svg';
import Google from './google.svg';
import Facebook from './facebook.svg';
import Grab from './grab.svg';
import Deliveroo from './deliveroo.svg';
import FoodPanda from './foodpanda.svg';
import Hamburger from './hamburger.svg';
import HamburgerDark from './hamburger-dark.svg';
import Home from './home.svg';
import StarDisabled from './star-disabled.svg';
import Star from './star.svg';
import FoodMenu from './food-menu.svg';
import FoodMenuWhite from './food-menu-white.svg';
import ArrowDown from './arrow-down.svg';
import ChevronUp from './chevron-up.svg';

import GrabColor from './partner_logos/grab_color.svg';
import GrabWhite from './partner_logos/grab_white.svg';
import DeliverooColor from './partner_logos/deliveroo_color.svg';
import DeliverooWhite from './partner_logos/deliveroo_white.svg';
import FoodPandaColor from './partner_logos/foodpanda_color.svg';
import FoodPandaWhite from './partner_logos/foodpanda_white.svg';
import UberEats from './partner_logos/uber_eats_logo.svg';
import Chope from './partner_logos/chope.svg';
import Quandoo from './partner_logos/quandoo.svg';
import TripAdvisor from './partner_logos/tripadvisor_logo.svg';
import Navigation from './navigation.svg';
import ArrowUpRight from './arrow-up-right.svg';
import Yelp from './yelp.svg';
import Grubhub from './Grubhub.svg';
import DoorDash from './DoorDash.svg';
import Coupon from './coupon.svg';

import DineIn from './dine-in.svg';
import Delivery from './delivery.svg';
import Takeaway from './takeaway.svg';
import Check from './check.svg';
import Calendar from './calendar.svg';
import ThankYou1 from './thankyou-1.svg';
import ThankYou2 from './thankyou-2.svg';
import ThankYou3 from './thankyou-3.svg';
import ThankYou1Mobile from './thankyou-1-mobile.svg';

import GoogleReview from './google-review.svg';
import FacebookReview from './facebook-review.svg';
import YelpReview from './yelp-review.svg';
import Sorry from './sorry.svg';
import Sorry2 from './sorry-2.svg';
import OfferRedeemed from './offer-redeemed.svg';
import Like from './like.svg';
import Dislike from './dislike.svg';
import Calendar2 from './calendar2.svg';
import NoVoucher from './no-voucher.svg';
import SurveyDeliverooLogo from './survey/Deliveroo-Logo.svg';
import SurveyFoodpandaLogo from './survey/Foodpanda-Logo.svg';
import SurveyGrabLogo from './survey/Grab-Logo.svg';
import SurveyDoorDashLogo from './survey/Door-Dash.svg';
import SurveyUberEatsLogo from './survey/Uber-Eats.svg';
import SurveyGrubHubLogo from './survey/Grub-Hub.svg';
import ThankyouSurveyNoVoucher from './thankyou-no-voucher.svg';
import ThankyouSurveyNoVoucherRes from './thankyou-no-voucher-res.svg';
import Crown from './bx-crown.svg';
import CopyClipboard from './copy-clipboard.svg';
import SurveyLogo from './survey-logo.svg';
import MomosLogo from './momos-logo.svg';
import DonateHeart from './donate-heart.svg';
import SearchIcon from './search.svg';
import SleepyCatIcon from './sleepy-cat.svg';
import DollarOffIcon from './dollar-off.svg';
import FreeItemIcon from './free-item.svg';
import PercentageOffIcon from './percentage-off.svg';
import VoucherErrorBlockout from './voucher-error-blockout.svg';
import VoucherErrorCalendar from './voucher-error-calendar.svg';
import VoucherErrorRedemption from './voucher-error-redemption.svg';
import VoucherErrorTime from './voucher-error-time.svg';
import VoucherError from './voucher-error.svg';
import ChevronRightIcon from './chevron-right.svg';
import DisplayScreenIcon from './display-screen.svg';
import InfoIcon from './info.svg';
import CompasIcon from './compas.svg';
import WhatsappIcon from './whatsapp.svg';
import TwitterIcon from './twitter.svg';
import YoutubeIcon from './youtube.svg';
import SnapchatIcon from './snapchat.svg';
import PinterestIcon from './pinterest.svg';
import SendMailIcon from './send-mail.svg';
import GoogleShareIcon from './google-share.svg';
import QuotationIcon from './quotation.svg';
import QuoteIcon from './quote.svg';
import ClockFilledIcon from './clock-filled.svg';
import LineIcon from './line.svg';
import SpotifyIcon from './spotify.svg';
import GooglePlayIcon from './google-play.svg';
import ApplePlayIcon from './apple-play.svg';
import BellIcon from './bell.svg';
import BellSuccessIcon from './bell-success.svg';
import ArrowBack from './arrow-back.svg';

export const BackArrowIcon = BackArrow;
export const ClockIcon = Clock;
export const CallIcon = Call;
export const PhoneIcon = Phone;
export const MarkerIcon = Marker;
export const CloseIcon = Close;
export const TruckIcon = Truck;
export const TruckSmallIcon = TruckSmall;
export const NoteIcon = Note;
export const MapPinIcon = MapPin;
export const Clock2Icon = Clock2;
export const Clock2WhiteIcon = Clock2White;
export const MapPinWhiteIcon = MapPinWhite;
export const FrameIcon = Frame;
export const GlobalIcon = Global;
export const GoogleIcon = Google;
export const GrabIcon = Grab;
export const FoodPandaIcon = FoodPanda;
export const FacebookIcon = Facebook;
export const DeliverooIcon = Deliveroo;
export const HamburgerIcon = Hamburger;
export const HamburgerDarkIcon = HamburgerDark;
export const StarDisabledIcon = StarDisabled;
export const StarIcon = Star;
export const HomeIcon = Home;
export const FoodMenuIcon = FoodMenu;
export const FoodMenuWhiteIcon = FoodMenuWhite;
export const ArrowDownIcon = ArrowDown;
export const ChevronUpIcon = ChevronUp;
export const NavigationIcon = Navigation;
export const ArrowUpRightIcon = ArrowUpRight;

export const GrabColorLogo = GrabColor;
export const GrabWhiteLogo = GrabWhite;
export const DeliverooColorLogo = DeliverooColor;
export const DeliverooWhiteLogo = DeliverooWhite;
export const FoodPandaColorLogo = FoodPandaColor;
export const FoodPandaWhiteLogo = FoodPandaWhite;
export const ChopeLogo = Chope;
export const QuandooLogo = Quandoo;
export const TripAdvisorLogo = TripAdvisor;
export const YelpIcon = Yelp;
export const GrubhubLogo = Grubhub;
export const DoorDashIcon = DoorDash;

export const CouponIcon = Coupon;

export const DineInIcon = DineIn;
export const DeliveryIcon = Delivery;
export const TakeawayIcon = Takeaway;
export const CheckIcon = Check;
export const CalendarIcon = Calendar;
export const ThankYouIcon1 = ThankYou1;
export const ThankYouIcon2 = ThankYou2;
export const ThankYouIcon3 = ThankYou3;
export const ThankYou1MobileIcon = ThankYou1Mobile;

export const GoogleReviewIcon = GoogleReview;
export const FacebookReviewIcon = FacebookReview;
export const YelpReviewIcon = YelpReview;
export const SorryIcon = Sorry;
export const SorryIcon2 = Sorry2;
export const OfferRedeemedIcon = OfferRedeemed;
export const LikeIcon = Like;
export const DislikeIcon = Dislike;
export const Calendar2Icon = Calendar2;
export const NoVoucherIcon = NoVoucher;
export const ThankyouSurveyNoVoucherIcon = ThankyouSurveyNoVoucher;
export const ThankyouSurveyNoVoucherResIcon = ThankyouSurveyNoVoucherRes;
export const CrownIcon = Crown;
export const CopyClipboardIcon = CopyClipboard;
export const SurveyColorLogo = SurveyLogo;
export const UberEatsLogo = UberEats;
export const MomosLogoImg = MomosLogo;
export const DonateHeartIcon = DonateHeart;
export const ArrowBackIcon = ArrowBack;
export {
  SearchIcon,
  DollarOffIcon,
  FreeItemIcon,
  PercentageOffIcon,
  SurveyDeliverooLogo,
  SurveyFoodpandaLogo,
  SurveyGrabLogo,
  SurveyDoorDashLogo,
  SurveyUberEatsLogo,
  SurveyGrubHubLogo,
  VoucherErrorBlockout,
  VoucherErrorCalendar,
  VoucherErrorRedemption,
  VoucherErrorTime,
  VoucherError,
  ChevronRightIcon,
  DisplayScreenIcon,
  InfoIcon,
  CompasIcon,
  WhatsappIcon,
  TwitterIcon,
  YoutubeIcon,
  SnapchatIcon,
  PinterestIcon,
  SendMailIcon,
  GoogleShareIcon,
  QuotationIcon,
  QuoteIcon,
  ClockFilledIcon,
  LineIcon,
  SpotifyIcon,
  GooglePlayIcon,
  ApplePlayIcon,
  BellIcon,
  BellSuccessIcon,
  SleepyCatIcon,
};
