import { notification } from 'antd';
// import { ReactNode } from 'react';

export const keydNotification = (message, title, key, type = 'info', duration = 0, placement = 'topRight', btn) => {
  notification[type]({
    message: title,
    description: message,
    duration,
    placement,
    key,
    btn,
  });
};

const showNotification = (message, title = 'Momos', type = 'info', duration = 10, placement = 'topRight', btn) => {
  notification[type]({
    message: title,
    description: message,
    duration,
    placement,
    btn,
  });
};

// export const keydNotification = (
//   message: string,
//   title: string,
//   key: string,
//   type: string = 'info',
//   duration: number = 0,
//   placement: string = 'topRight',
//   btn: ReactNode,
// ) => {
//   notification[t]({
//     message: title,
//     description: message,
//     duration,
//     placement,
//     key,
//     btn,
//   });
// };

// const showNotification = (
//   message: string,
//   title: string = 'Momos',
//   type: string = 'info',
//   duration: number = 10,
//   placement: string = 'topRight',
//   btn: ReactNode,
// ) => {
//   notification[type]({
//     message: title,
//     description: message,
//     duration,
//     placement,
//     btn,
//   });
// };

export default showNotification;
