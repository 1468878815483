import { PUBLIC_URL } from 'constant';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const siteUrl = PUBLIC_URL || 'https://restaurant.momos.io';

i18n
  .use(Backend) // Loads translations from your backend (e.g., /locales path)
  .use(initReactI18next) // Connect with React
  .init({
    fallbackLng: 'en-US', // Default language
    debug: false, // Set to false in production
    interpolation: {
      escapeValue: false, // React already safely escapes
    },
    keySeparator: false, // we do not use keys in form messages.welcome
    backend: {
      loadPath: `${siteUrl}/locales/{{lng}}/{{ns}}.json`,
    },
    ns: ['common'],
    defaultNS: 'common',
  });

export default i18n;
