import * as Sentry from '@sentry/nextjs';
import { GA_TRACKING_ID, isMockingEnvEnabled } from 'constant';
import { isClient } from 'utils/common';
import { v4 as uuidv4, validate } from 'uuid';

(function () {
  initSessionId();
})();

function initSessionId() {
  if (isClient()) {
    const sessionId = uuidv4();
    sessionStorage.setItem('sessionId', sessionId);
    return sessionId;
  }
}

export function getSessionId() {
  return sessionStorage.getItem('sessionId') || initSessionId();
}

export const pageview = (url: string) => {
  if (window.gtag) {
    window.gtag('config', GA_TRACKING_ID as string, {
      page_path: url,
    });
  }
};

interface EventProps {
  action: string;
  category?: string;
  label?: string;
  value?: string;
}

export const event = ({ action, category, label, value }: EventProps) => {
  if (isMockingEnvEnabled) {
    return;
  }
  if (window.gtag) {
    try {
      const time = new Date();

      const valueArray = typeof value === 'string' && value?.match(/.{1,100}/g);
      const values =
        Array.isArray(valueArray) &&
        valueArray?.reduce((obj, currString, index) => {
          const key = `customValue${index ? index : ''}`;
          return {
            ...obj,
            [key]: currString,
          };
        }, {});

      const pageLocation = isClient() ? window.location.href : '';
      const pageLocationsArray = typeof pageLocation === 'string' && pageLocation?.match(/.{1,100}/g);
      const pageLocations =
        Array.isArray(pageLocationsArray) &&
        pageLocationsArray?.reduce((obj, currString, index) => {
          const key = `customPageLocation${index ? index : ''}`;
          return {
            ...obj,
            [key]: currString,
          };
        }, {});

      const sessionId = getSessionId();
      const uuid = uuidv4();
      const customTime = time.toISOString();

      const eventParams = {
        event_category: category,
        event_label: label,
        value: value,
        uuid: uuid,
        sessionId,
        customLabel: label,
        customAction: action,
        customTime: customTime,
        ...values,
        ...pageLocations,
      };
      if (!validate(uuid) || eventParams.customTime === 'other') {
        Sentry?.captureException?.(eventParams);
      }
      window.gtag('event', action, eventParams);
    } catch (err) {
      Sentry?.captureException?.(err);
    }
  }
};
