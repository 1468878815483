// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { SENTRY_URL } from 'constant';

Sentry.init({
  dsn: SENTRY_URL,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.01,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps,
  release: 'momos-cp@' + process.env.npm_package_version,
  autoSessionTracking: false,
  maxBreadcrumbs: 50,
  debug: false,
  environment: process.env.NODE_ENV || 'production',
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'window[arg.funcName] is not a function',
    'ResizeObserver loop completed with undelivered notifications',
  ],
});
